<template>
  <div>
    <H3 style="text-align: center;">{{$t('All.新增产品注意事项')}}</H3>
    <el-form ref="elForm" :model="formData" style="padding: 20px;" :rules="rules" size="medium" label-width="100px"
      label-position="top">
      <el-form-item :label="$t('All.料号')">
        <el-input v-model="formData.Item" onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" @blur="formData.Item=formData.Item.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" oninput="if (value.trim() === '') { value = null; }" :placeholder="$t('All.请输入料号')" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('All.品名')" prop="Productname">
        <el-input v-model="formData.Productname" :placeholder="$t('All.请输入品名')" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('All.客户')">
        <el-input v-model="formData.Custom" :placeholder="$t('All.请输入客户')" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('All.简述')" prop="Sketch">
        <el-input v-model="formData.Sketch" maxlength="20" show-word-limit :placeholder="$t('All.请输入简述')" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('All.详细描述')" prop="Details">
        <el-input v-model="formData.Details" type="textarea" :placeholder="$t('All.请输入详细描述')"
          :autosize="{minRows: 4, maxRows: 4}" maxlength="200" show-word-limit :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item size="large" style="text-align: center;">
        <el-button type="primary" style="width: 40%;" @click="submit">{{$t('All.提交')}}</el-button>
        <el-button style="width: 40%;" @click="Close()">{{$t('All.取消')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['Close'],
  data () {
    return {
      formData: {
        Item: undefined,
        Productname: undefined,
        Custom: undefined,
        Sketch: undefined,
        Details: undefined,
        Numbers: '',
        Factory: this.$store.state.Login.Factory,
        User: this.$store.state.Login.User,
        Username: this.$store.state.Login.Username
      },
      rules: {
        Item: [{
          required: true,
          message: this.$t('All.请输入料号'),
          trigger: 'blur'
        }],
        Productname: [{
          required: true,
          message: this.$t('All.请输入品名'),
          trigger: 'blur'
        }],
        Custom: [{
          required: true,
          message: this.$t('All.请输入客户'),
          trigger: 'blur'
        }],
        Sketch: [{
          required: true,
          message: this.$t('All.请输入简述'),
          trigger: 'blur'
        }],
        Details: [{
          required: true,
          message: this.$t('All.请输入详细描述'),
          trigger: 'blur'
        }]
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.ProductCount()
  },
  mounted () {},
  methods: {
    submit () {
      this.$refs.elForm.validate(async valid => {
        if (!valid) return this.$message.error(this.$t('All.请完善表单后在进行提交'))
        const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
        const { data: res } = await this.$http.post(
          '/api/Product/Addform',
          this.formData
        )
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        this.$message.success(this.$t('All.提交成功'))
        this.Close()
        loadingInstance.close()
      })
    },
    async ProductCount () {
      const { data: res } = await this.$http.post(
        '/api/Product/ProductCount',
        this.$store.state.Login
      )
      if (res.status !== 200) return this.$message.error(res.msg)
      this.formData.Numbers = res.response
      this.$message.success(this.$t('All.获取系统单号成功'))
    }
  }
}

</script>
<style>
.el-upload__tip {
  line-height: 1.2;
}

</style>
